<template>
    <ul
        v-if="!expanded"
        class="absolute bg-white collapse group-hover:visible w-32 text-[var(--grey-700)] rounded-[4px] border border-[var(--grey-800)] flex flex-col drop-shadow-[0_2px_4px_rgba(19,24,33,0.05)]"
    >
        <li class="h-10 py-3 pl-2.5 border-b border-[var(--grey-200_)]">
            <InertiaLink
                href="/players/me/rounds"
                class="flex h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                Home
            </InertiaLink>
        </li>
        <li class="h-10 py-3 pl-2.5 border-b border-[var(--grey-200_)]">
            <!-- These needs to use <a> instead of InertiaLink to avoid CORS issues in some edge cases -->
            <a
                href="/players/me/clubs"
                class="flex h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                My Clubs
            </a>
        </li>
        <li class="h-10 py-3 pl-2.5 border-b border-[var(--grey-200_)]">
            <a
                href="/account/info"
                class="flex h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                Account Info
            </a>
        </li>
        <li class="h-10 py-3 pl-2.5 border-b border-[var(--grey-200_)]">
            <a
                href="/account/settings"
                class="flex h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                Settings
            </a>
        </li>
        <li class="h-10 py-3 pl-2.5 border-b border-[var(--grey-200_)]">
            <a
                href="/account/subscription"
                class="flex h-4 hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                Subscription
            </a>
        </li>
        <li class="h-10 py-3 pl-2.5">
            <a
                :href="$page.props.logoutRoute"
                class="flex h-4 text-[var(--medium-warning)] hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out"
            >
                Log Out
            </a>
        </li>
    </ul>
    <ul
        v-else
        class="border border-[var(--grey-200)] bg-[var(--grey-600)] font-bold rounded"
    >
        <li>
            <InertiaLink
                href="/players/me/rounds"
                class="relative align-middle hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg border-b border-b-[var(--grey-500)]"
            >
                Home
            </InertiaLink>
        </li>
        <li>
            <a
                href="/players/me/clubs"
                class="relative align-middle hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg border-b border-b-[var(--grey-500)]"
            >
                My Clubs
            </a>
        </li>
        <li>
            <a
                href='/account/info'
                class="relative align-middle hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg border-b border-b-[var(--grey-500)]"
            >
                Account Info
            </a>
        </li>
        <li>
            <a
                href="/account/settings"
                class="relative align-middle hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg border-b border-b-[var(--grey-500)]"
            >
                Settings
            </a>
        </li>
        <li>
            <a
                href="/account/subscription"
                class="relative align-middle hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg border-b border-b-[var(--grey-500)]"
                :headers="{ v: 2 }"
            >
                Subscription
            </a>
        </li>
        <li>
            <a
                :href="$page.props.logoutRoute"
                class="relative align-middle text-[var(--medium-warning)] hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out flex justify-center py-3 px-[18px] text-lg"
            >
                Log Out
            </a>
        </li>
    </ul>
</template>

<script setup></script>

<script>
export default {
    components: {},
    props: {
        expanded: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
